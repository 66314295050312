
import React from 'react'

export const Logo = ({ color = '#000000' }) => (
  <svg width="207" height="22" viewBox="0 0 207 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.5 21.3824V0.522705H3.09345V8.76094H15.2407V0.522705H17.8156V21.3824H15.2407V11.1605H3.09345V21.3824H0.5Z" fill={color}/>
    <path d="M48.208 21.3824L58.3025 0.522705H60.4298L70.6366 21.3824H67.8563L65.0575 15.771H53.6755L50.9513 21.3824H48.2086H48.208ZM54.8507 13.3334H63.9192L59.3662 4.0936L54.8507 13.3334Z" fill={color}/>
    <path d="M83.1357 21.3824H81.5313L71.6045 0.522705H74.3848L82.3335 17.2446L90.2822 0.522705H93.1186L83.1357 21.3824Z" fill={color}/>
    <path d="M106.446 21.3824V10.9338L97.1729 0.522705H100.382L107.753 8.76094L115.086 0.522705H118.332L109.003 10.9713V21.3824H106.447H106.446Z" fill={color}/>
    <path d="M121.451 18.2461L122.944 15.9977C124.399 17.1438 125.914 18.0194 127.487 18.6238C129.061 19.2282 130.619 19.5311 132.162 19.5311C134.065 19.5311 135.61 19.1217 136.799 18.3029C137.987 17.4841 138.581 16.4322 138.581 15.1472C138.581 14.127 138.155 13.2862 137.303 12.6244C136.45 11.9631 135.272 11.5692 133.767 11.4433C133.406 11.4052 132.884 11.3678 132.199 11.3297C128.816 11.0901 126.508 10.6872 125.277 10.1202C124.319 9.65399 123.591 9.05282 123.093 8.31539C122.596 7.57861 122.347 6.75014 122.347 5.83062C122.347 4.15559 123.087 2.77631 124.568 1.69277C126.048 0.609238 127.97 0.0681152 130.333 0.0681152C132.05 0.0681152 133.745 0.35482 135.418 0.927583C137.091 1.50099 138.681 2.34819 140.186 3.46918L138.693 5.62334C137.424 4.59017 136.09 3.81271 134.691 3.28967C133.291 2.76727 131.851 2.50575 130.371 2.50575C128.816 2.50575 127.535 2.81118 126.527 3.42204C125.52 4.03355 125.016 4.7981 125.016 5.71762C125.016 6.0831 125.097 6.41694 125.258 6.71915C125.419 7.02135 125.669 7.30482 126.004 7.56957C126.85 8.21208 128.679 8.62147 131.49 8.79775C132.784 8.8733 133.785 8.95531 134.494 9.04313C136.584 9.2827 138.238 9.96265 139.457 11.0836C140.677 12.2046 141.286 13.5839 141.286 15.2215C141.286 17.1742 140.45 18.7801 138.777 20.0399C137.104 21.2998 134.911 21.9293 132.199 21.9293C130.147 21.9293 128.241 21.6271 126.481 21.0221C124.72 20.4177 123.044 19.4917 121.452 18.2448L121.451 18.2461Z" fill={color}/>
    <path d="M150.09 21.3824V0.409698H152.683V21.3824H150.09Z" fill={color}/>
    <path d="M171.525 0.522705C174.771 0.522705 177.387 1.49259 179.371 3.43237C181.355 5.37215 182.347 7.8976 182.347 11.0094C182.347 14.1212 181.37 16.6589 179.417 18.5483C177.464 20.4377 174.834 21.3824 171.525 21.3824H162.699V0.522705H171.525ZM171.525 2.92224H165.255V18.9829H171.525C174.062 18.9829 176.059 18.2616 177.514 16.819C178.97 15.3771 179.698 13.4276 179.698 10.9713C179.698 8.51492 178.958 6.60678 177.478 5.13258C175.997 3.65902 174.013 2.9216 171.525 2.9216L171.525 2.92224Z" fill={color}/>
    <path d="M189.465 21.3824V0.522705H205.66V2.92224H192.02V9.04377H203.699V11.4433H192.02V18.9822H206.5V21.3818H189.464L189.465 21.3824Z" fill={color}/>
    <path d="M25.959 21.3934V0.533691H42.1548V2.93323H28.5148V9.05476H40.1933V11.4543H28.5148V18.9932H42.994V21.3927H25.959V21.3934Z" fill={color}/>
  </svg>
)

export const Instagram = ({ color = '#000000' }) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M5.58763 0.75H12.6741C15.3353 0.75 17.5114 2.89923 17.5114 5.52483V12.5921C17.5114 15.2183 15.3353 17.3669 12.6741 17.3669H5.58763C2.92647 17.3669 0.75 15.2183 0.75 12.5921V5.52483C0.75 2.89923 2.92644 0.75 5.58763 0.75ZM9.08521 4.5721C11.6712 4.5721 13.7697 6.6706 13.7697 9.2566C13.7697 11.8429 11.6712 13.9411 9.08521 13.9411C6.49858 13.9411 4.40039 11.8429 4.40039 9.2566C4.40039 6.6706 6.49858 4.5721 9.08521 4.5721ZM9.08501 6.15432C10.7975 6.15432 12.1873 7.54384 12.1873 9.2566C12.1873 10.9694 10.7975 12.3592 9.08501 12.3592C7.37194 12.3592 5.98242 10.9694 5.98242 9.2566C5.98242 7.54384 7.37194 6.15432 9.08501 6.15432ZM13.6178 3.92285C14.0378 3.92285 14.3784 4.26342 14.3784 4.68319C14.3784 5.10327 14.0378 5.44385 13.6178 5.44385C13.198 5.44385 12.8574 5.10327 12.8574 4.68319C12.8574 4.26342 13.198 3.92285 13.6178 3.92285ZM6.16642 2.10742H12.0954C14.3216 2.10742 16.1424 3.91917 16.1424 6.13309V12.0917C16.1424 14.3056 14.3217 16.1171 12.0954 16.1171H6.16642C3.94016 16.1171 2.11914 14.3056 2.11914 12.0917V6.13304C2.11914 3.91917 3.94016 2.10742 6.16642 2.10742Z" fill={color}/>
  </svg>
)

export const Facebook = ({ color = '#000000' }) => (
  <svg width="9" height="18" viewBox="0 0 9 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M5.49751 17.25H1.99846V8.99896H0.25V6.15593H1.99846V4.44907C1.99846 2.12986 2.9852 0.75 5.79043 0.75H8.12527V3.59407H6.66599C5.57394 3.59407 5.50178 3.99137 5.50178 4.73285L5.49697 6.15593H8.1413L7.83181 8.99896H5.49697V17.25H5.49751Z" fill={color}/>
  </svg>
)

export const LinkedIn = ({ color = '#000000' }) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M0.539062 5.26367H3.60191V15.9381H0.539062V5.26367Z" fill={color}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M1.99254 3.9283H1.97037C0.861644 3.9283 0.142822 3.1123 0.142822 2.07897C0.142822 1.02451 0.882894 0.224609 2.01379 0.224609C3.14376 0.224609 3.83856 1.0225 3.86074 2.07595C3.86074 3.10928 3.14376 3.9283 1.99254 3.9283Z" fill={color}/>
    <path fillRule="evenodd" clipRule="evenodd" d="M15.8572 15.9387H12.3841V10.4138C12.3841 8.96797 11.8409 7.98193 10.6462 7.98193C9.73243 7.98193 9.22427 8.64701 8.98774 9.28995C8.89904 9.51935 8.9129 9.84032 8.9129 10.1623V15.9387H5.47217C5.47217 15.9387 5.51652 6.15272 5.47217 5.26327H8.9129V6.93854C9.11617 6.20605 10.2156 5.16064 11.9702 5.16064C14.147 5.16064 15.8572 6.69706 15.8572 10.0023V15.9387Z" fill={color}/>
  </svg>
)

export const Search = ({ color = '#000000' }) => (
  <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.491 8.84128C10.1902 7.96429 10.5736 6.85386 10.5736 5.73008C10.5736 2.96155 8.31204 0.700012 5.52952 0.700012C2.74756 0.700012 0.5 2.96155 0.5 5.73008C0.5 8.49862 2.76154 10.7602 5.53007 10.7602C6.6405 10.7602 7.75035 10.3763 8.64126 9.67758L12.1637 13.2L13.0001 12.3776L9.491 8.84128ZM5.53015 9.56782C3.43295 9.56782 1.71967 7.85453 1.71967 5.75733C1.71967 3.66013 3.43295 1.94685 5.53015 1.94685C7.62735 1.94685 9.34064 3.66013 9.34064 5.75733C9.34064 7.85453 7.62735 9.56782 5.53015 9.56782Z" fill={color}/>
  </svg>
)

export const Icon = ({ color = '#FFFFFF' }) => (
  <svg width="32" height="37" viewBox="0 0 32 37" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M-0.00206184 5.91144H4.86914V32.4773H-0.00206184V5.91144Z" fill={color}/>
    <path d="M27.13 5.91144H32V32.4773H27.13V5.91144Z" fill={color}/>
    <path d="M13.5655 0H18.4355V15.974H13.5655V0Z" fill={color}/>
    <path d="M13.5655 21.0272H18.4355V37H13.5655V21.0272Z" fill={color}/>
  </svg>
)

export const Tick = ({ color = '#666868' }) => (
  <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.62853 15.4445C7.30884 15.4445 6.99816 15.3401 6.74294 15.1467L0.48053 10.3964L2.25299 8.05801L7.27394 11.8672L15.0471 0.207642L17.4873 1.83444L8.8492 14.7909C8.57721 15.1995 8.1196 15.4445 7.62846 15.4445L7.62853 15.4445Z" fill={color}/>
  </svg>
)

export const Play = () => (
  <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M43 31L23 47L23 15L43 31Z" fill="white"/>
    <circle cx="31" cy="31" r="30" stroke="white" stroke-width="2"/>
  </svg>
)
