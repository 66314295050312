import React from 'react'
import PropTypes from 'prop-types'

import layoutQuery from "../hooks/use-layout-query"

import '../assets/scss/main.scss'

import Header from '../components/header'
import Footer from '../components/footer'

const Layout = ({ children, location, pageContext }) => {
  const data = layoutQuery()

  React.useEffect(() => {
    document.documentElement.style.setProperty(`--mainPaddingMobile`, pageContext?.header === 'white' ? '0' : '70px');
    document.documentElement.style.setProperty(`--mainPaddingDesktop`, pageContext?.header === 'white' ? '0' : '90px');
    document.documentElement.style.setProperty(`--headerColour`, pageContext?.header === 'black' ? '#000000' : '#FFFFFF');
    document.documentElement.style.setProperty(`--headerBgColour`, pageContext?.header === 'black' ? '#FFFFFF' : 'transparent');
  }, [pageContext])

  return (
    <>
      <Header location={location} data={data} pageContext={pageContext} />
      <main>{children}</main>
      <Footer data={data} />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
